import React, { Component } from "react";

const ServiceList = [
    {
        icon: '01',
        title: 'Business Stratagy',
        description: 'Your corporate identity is the first touchpoint customers have with your business and a powerful driver for success. Our team can help your brand resonate with its audience and carry on an emotional impact'
    },
    {
        icon: '02',
        title: 'Website Development',
        description: 'You need solid planning, a touch of storytelling and a taste of inspiration. From pencil sketches to whiteboard sessions, we think about your project from every angle and every marketing platform.'
    },
    {
        icon: '03',
        title: 'Marketing & Reporting',
        description: 'We brainstorm multi-channel marketing tactics to get you noticed. We leverage the power of imagination and the accuracy of data to revise, amend, and repeat what’s working. And this is how the magic happens.'
    },
]

class ServiceOne extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceList.map( (val , i) => (
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                            <div className="service service__style--1">
                                <div className="icon">
                                    <img src={`/assets/images/icons/icon-${val.icon}.png`} alt="Digital Agency"/>
                                </div>
                                <div className="content">
                                    <h4 className="title">{val.title}</h4>
                                    <p>{val.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceOne;