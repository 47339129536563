import React, { Component } from "react";

class About extends Component{
    render(){
        let title = 'About',
        description = 'ELEVATE is a Cape Town Branding Agency dedicated to elevating meaningful brands by leveraging purpose, strategy, design, video & technology to deliver differentiation through strong positioning & beautifully designed brand touch points.';
        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">

                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about-pic.jpg" alt="About Images"/>
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">{title}</h2>
                                        <p className="description">{description}</p>
                                    </div>
                                    <div className="row mt--30 mt_sm--10">
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">Our Focus</h3>
                                                <p>We focus on Brand Positioning: With in-depth research and analysis we identify how your brand should be positioned in the marketplace so that you will be the 1st or 2nd choice your audience considers when making a buying decision.</p>
                                            </div>
                                        </div>
																				<div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">Our Promise</h3>
                                                <p>By working with us , you will gain clarity on your purpose, a clear sense of direction with your brand and have access to our resources that will enable you to effectively execute strategies that will position your brand as a leader in its respective category.</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">Our Purpose</h3>
                                                <p>To elevate meaningful brands so they can in turn elevate the lives of those they serve.</p>
                                            </div>
                                        </div>
																				<div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">Our Belief</h3>
                                                <p>We start with a simple and indisputable truth: great brands stand for something. Brands that commit to a stance—and those who experience them—win. It all starts with focus.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default About;