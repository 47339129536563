import React, { Component } from "react";
import { FiCast , FiLayers , FiUsers , FiMonitor } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiCast />,
        title: 'Brand Stratagy',
        description: 'Your corporate identity is the first touchpoint customers have with your business and a powerful driver for success. Our team can help your brand resonate with its audience and carry on an emotional impact'
    },
    {
        icon: <FiLayers />,
        title: 'Website Development',
        description: 'You need solid planning, a touch of storytelling and a taste of inspiration. From pencil sketches to whiteboard sessions, we think about your project from every angle and every marketing platform.'
    },
    {
        icon: <FiUsers />,
        title: 'Marketing',
        description: 'We brainstorm multi-channel marketing tactics to get you noticed. We leverage the power of imagination and the accuracy of data to revise, amend, and repeat what’s working. And this is how the magic happens.'
    },
    {
        icon: <FiMonitor />,
        title: 'Media',
        description: 'A brand or explainer video serves a powerful tool in your marketing. When done well it can be astonishingly effective in captivating online audiences as it communicates your story in a compelling way and helps to build relationships with potential customers.'
    }
]

class ServiceTwo extends Component{
    render(){
        let title = 'Services',
        description = '';
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-4 col-12">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            <h2 className="title">{title}</h2>
                            <p>{description}</p>
                            {/* <div className="service-btn">
                                <a className="btn-transparent rn-btn-dark" href="/service"><span className="text">Request Custom Service</span></a>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-lg-8 col-12 mt_md--50">
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                    {/* <a href="/service-details"> */}
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    {/* </a> */}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
